// import { getBaseUrl } from "../../utils/helpers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Create your service using a base URL and expected endpoints
export const ExchangeApi = createApi({
  reducerPath: "ExchangeApi",
  baseQuery: fetchBaseQuery({
    // baseUrl: `${getBaseUrl()}`,
    prepareHeaders: (headers) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      // const token = (getState() as RootState).user.token;
      // if (token) {
      //   headers.set("Authorization", `Bearer ${token}`);
      //   // headers.set('Authentication', `Bearer ${token}`);
      // }
      // headers.set('x-auth-apiKey', '1');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getRates: builder.mutation({
      query: () => ({
        url: "https://v6.exchangerate-api.com/v6/4c32ea4d4b54d22da7d4db08/latest/GBP",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetRatesMutation } = ExchangeApi;
