import React from "react";

const FloatingAssistant = () => {
  return (
    <div className="fixed bottom-4 right-4">
      <button className="bg-[#007E57] h-[50px] w-[180px] rounded-[25px] flex flex-row gap-2 px-[10px] text-white items-center">
        <img
          src="https://www.iombank.com/content/dam/natwest/ccm/isle-of-man-icons/ask-cora.svg"
          height="30"
          width="30"
          alt="cora"
        />
        <p>Chat to Cora</p>
      </button>
    </div>
  );
};

export default FloatingAssistant;
