// export const getBaseUrl = () =>
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:8888/iomassetmanagement.com/web_api/"
//     : "https://iomassetmanagement.com/web_api/";
export const modes = {
  iom: {
    base_url:
      process.env.NODE_ENV === "development"
        ? "http://localhost:8888/iomassetmanagement.com/web_api/"
        : "https://iomassetmanagement.com/web_api/",
    domain: "iomassetmanagement.com",
    title: "Isle of man Bank",
    parent: "The Royal Bank of Scotland",
    customerNumber: "0311477850",
  },
  aai: {
    base_url: "https://aaibwealthmanagement.com/web_api/",
    domain: "aaibwealthmanagement.com",
    title: "AAIB Wealth Management",
    parent: "AAIB Wealth Management",
    customerNumber: "2806562894",
  },
};

export const currentMode = modes.iom;

export const getBaseUrl = () => currentMode.base_url;

export const numberWithCommas = (x: number, decimals?: number) => {
  return x.toFixed(decimals || 0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const currencies = [
  {
    title: "GBP",
    symbol: "£",
  },
  {
    title: "USD",
    symbol: "$",
  },
  {
    title: "EUR",
    symbol: "€",
  },
];
