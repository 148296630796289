import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

type AuthState = {
  access_code: string;
  currency: string;
  rates: {
    GBP: number;
    USD: number;
    EUR: number;
  };
  rate_last_updated: string;
};

export const defaultAccessState = {
  access_code: "",
  currency: "GBP",
  rates: {
    EUR: 1,
    GBP: 1,
    USD: 1,
  },
  rate_last_updated: "",
} as AuthState;

const slice = createSlice({
  name: "access",
  initialState: defaultAccessState,
  reducers: {
    setAccessCode: (
      state,
      { payload: { code } }: PayloadAction<{ code: string }>
    ) => {
      state.access_code = code;
    },
    setCurrency: (
      state,
      { payload: { currency } }: PayloadAction<{ currency: string }>
    ) => {
      state.currency = currency;
    },
    setRates: (
      state,
      { payload: { rates } }: PayloadAction<{ rates: typeof state.rates }>
    ) => {
      state.rates = rates;
      state.rate_last_updated = new Date().toISOString();
    },
  },
});

export const { setAccessCode, setCurrency, setRates } = slice.actions;

export default slice.reducer;

export const selectAccessCode = (state: RootState) => state.access.access_code;
export const selectCurrency = (state: RootState) => state.access.currency;
export const selectRates = (state: RootState) => state.access.rates;
export const selectRateLastUpdate = (state: RootState) =>
  state.access.rate_last_updated;
