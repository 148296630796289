import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectToken,
  selectCurrentUser,
  selectAccounts,
  selectBeneficiaries,
  selectTrigger,
} from "../reducers/user.reducer";

export const useAuth = () => {
  const user = useSelector(selectCurrentUser);
  const token = useSelector(selectToken);
  const accounts = useSelector(selectAccounts);
  const beneficiaries = useSelector(selectBeneficiaries);
  const userTrigger = useSelector(selectTrigger);

  return useMemo(
    () => ({
      user,
      token,
      accounts,
      beneficiaries,
      userTrigger,
    }),
    [user, token, accounts, beneficiaries, userTrigger]
  );
};
