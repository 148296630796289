import logo from "../assets/images/iombank.svg";
import { FaLock } from "react-icons/fa6";
import { currentMode } from "../utils/helpers";

const LoginNavbar = () => {
  return (
    <div className="bg-primary flex flex-row justify-between items-center py-4 lg:px-60 sm:px-5 mb-10">
      {currentMode.base_url.includes("iom") ? (
        <img src={logo} alt="logo" />
      ) : (
        <p className="text-white text-2xl font-semibold">AAIB</p>
      )}
      <div>
        <button className="bg-secondary text-white hover:bg-white hover:text-secondary h-[40px] w-[119px] flex flex-row items-center justify-center gap-1">
          <p>Log in</p> <FaLock />
        </button>
      </div>
    </div>
  );
};

export default LoginNavbar;
