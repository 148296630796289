import { Account, Beneficiary, User } from "../../utils/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

type AuthState = {
  user?: User | null;
  token?: string;
  accounts: Account[];
  beneficiaries: Beneficiary[];
  userTrigger: boolean;
};

export const defaultUserState = {
  user: null,
  accounts: [],
  beneficiaries: [],
  userTrigger: false,
} as AuthState;

const slice = createSlice({
  name: "user",
  initialState: defaultUserState,
  reducers: {
    setCredentials: (
      state,
      { payload: { user, token } }: PayloadAction<{ user: User; token: string }>
    ) => {
      state.user = user;
      state.token = token;
      // state.tokenExpiration = new Date(
      //   new Date().getTime() + 1 * 60 * 60 * 1000,
      // ).toISOString();
    },
    setAccounts: (
      state,
      { payload: { accounts } }: PayloadAction<{ accounts: Account[] }>
    ) => {
      state.accounts = accounts;
    },
    setbeneficiaries: (
      state,
      {
        payload: { beneficiaries },
      }: PayloadAction<{ beneficiaries: typeof state.beneficiaries }>
    ) => {
      state.beneficiaries = beneficiaries;
    },
    setTrigger: (
      state,
      {
        payload: { trigger },
      }: PayloadAction<{ trigger: typeof state.userTrigger }>
    ) => {
      state.userTrigger = trigger;
    },
    updateCredentials: (
      state,
      { payload: { user } }: PayloadAction<{ user: User }>
    ) => {
      state.user = user;
    },
    updateToken: (
      state,
      { payload: { token } }: PayloadAction<{ token: string }>
    ) => {
      state.token = token;
      // state.tokenExpiration = new Date(
      //   new Date().getTime() + 1 * 60 * 60 * 1000,
      // ).toISOString();
    },
    signUserOut: (state) => {
      state.token = undefined;
      state.user = undefined;
      state.accounts = [];
      state.beneficiaries = [];
    },
  },
});

export const {
  setCredentials,
  updateCredentials,
  signUserOut,
  updateToken,
  setAccounts,
  setbeneficiaries,
  setTrigger,
} = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.user.user;
export const selectTrigger = (state: RootState) => state.user.userTrigger;
export const selectToken = (state: RootState) => state.user.token;
export const selectAccounts = (state: RootState) => state.user.accounts;
export const selectBeneficiaries = (state: RootState) =>
  state.user.beneficiaries;
