import { lazy, Suspense, useCallback, useEffect } from "react";
import ErrorBoundary from "./components/ErrorBoundary";
import { Routes, Route } from "react-router-dom";
import Loader from "./components/Loader";
import RouteHandler from "./components/RouteHandler";
import Step2Login from "./pages/step2";
import { useAuthenticateMutation } from "./state/services/auth.service";
import { useDispatch } from "react-redux";
import { useAuth } from "./state/hooks/user.hook";
import {
  setAccounts,
  setbeneficiaries,
  setTrigger,
  updateCredentials,
} from "./state/reducers/user.reducer";
import { useGetRatesMutation } from "./state/services/exchange.service";
import { useAccess } from "./state/hooks/access.hook";
import { setRates } from "./state/reducers/access.reducer";

const Landing = lazy(() => import("./pages/landing"));
const Login = lazy(() => import("./pages/login"));
const Forgot = lazy(() => import("./pages/forgot"));
const IbankHome = lazy(() => import("./pages/ibank"));
const Payments = lazy(() => import("./pages/pay"));
const Transfer = lazy(() => import("./pages/transfer"));
const Accounts = lazy(() => import("./pages/accounts"));
const BackLogin = lazy(() => import("./pages/backlogin"));
const Suspended = lazy(() => import("./pages/suspended"));

const paths = [
  {
    path: "/",
    component: <Landing />,
    protected: false,
    accessCodeReverse: true,
  },
  {
    path: "/login",
    component: <Login />,
    protected: false,
    accessCodeReq: true,
    reverseProtect: true,
    suspended: false,
  },
  {
    path: "/forgot",
    component: <Forgot />,
    protected: false,
    accessCodeReq: true,
    reverseProtect: true,
    suspended: false,
  },
  {
    path: "/step2/:user_id",
    component: <Step2Login />,
    protected: false,
    accessCodeReq: true,
    reverseProtect: true,
    suspended: false,
  },
  {
    path: "/ibank",
    component: <IbankHome />,
    protected: true,
    accessCodeReq: true,
    suspended: false,
  },
  {
    path: "/payments",
    component: <Payments />,
    protected: true,
    accessCodeReq: true,
    suspended: false,
  },
  {
    path: "/transfer",
    component: <Transfer />,
    protected: true,
    accessCodeReq: true,
    suspended: false,
  },
  {
    path: "/suspended",
    component: <Suspended />,
    protected: false,
    accessCodeReq: true,
  },
  {
    path: "/backlogin/:user_id",
    component: <BackLogin />,
    protected: false,
    accessCodeReq: false,
  },
  {
    path: "/accounts/:type",
    component: <Accounts />,
    protected: true,
    accessCodeReq: true,
    suspended: false,
  },
];

const App = () => {
  const [authencticate] = useAuthenticateMutation();
  const [getRates] = useGetRatesMutation();
  const dispatch = useDispatch();
  const { user, userTrigger } = useAuth();
  const { rate_last_updated } = useAccess();
  const fetchUser = useCallback(async () => {
    try {
      if (!user?.id) {
        return;
      }
      const bodyForm = new FormData();
      bodyForm.append("user_id", user.id);
      const response = await authencticate(bodyForm);
      if ((response as any)?.error) {
        return;
      }
      const r = (response as any).data.data;
      dispatch(
        setAccounts({
          accounts: r.accounts,
        })
      );
      dispatch(
        setbeneficiaries({
          beneficiaries: r.beneficiaries,
        })
      );
      dispatch(
        updateCredentials({
          user: r.user,
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, [authencticate, dispatch, user]);

  const fetchRates = useCallback(async () => {
    try {
      if (
        new Date().toDateString() === new Date(rate_last_updated).toDateString()
      ) {
        return;
      }
      const response = await getRates({});
      if ((response as any)?.error) {
        return;
      }
      const r = (response as any).data;
      dispatch(
        setRates({
          rates: {
            EUR: r.conversion_rates.EUR,
            GBP: r.conversion_rates.GBP,
            USD: r.conversion_rates.USD,
          },
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, [getRates, rate_last_updated, dispatch]);

  useEffect(() => {
    fetchUser();
    fetchRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userTrigger) {
      fetchUser();
      dispatch(
        setTrigger({
          trigger: false,
        })
      );
    }
  }, [fetchUser, userTrigger, dispatch]);

  return (
    <div>
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <Routes>
            {paths.map((p) => (
              <Route
                key={p.path}
                path={p.path}
                element={
                  <RouteHandler
                    accessCodeReq={p.accessCodeReq}
                    accessCodeReverse={p.accessCodeReverse}
                    reverseProtect={p.reverseProtect}
                    protectedRoute={p.protected}
                    suspended={p?.suspended}
                  >
                    {p.component}
                  </RouteHandler>
                }
              />
            ))}
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default App;
