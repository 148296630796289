import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectAccessCode,
  selectCurrency,
  selectRates,
  selectRateLastUpdate,
} from "../reducers/access.reducer";

export const useAccess = () => {
  const accessCode = useSelector(selectAccessCode);
  const currency = useSelector(selectCurrency);
  const rates = useSelector(selectRates);
  const rate_last_updated = useSelector(selectRateLastUpdate);

  return useMemo(
    () => ({
      accessCode,
      currency,
      rates,
      rate_last_updated,
    }),
    [accessCode, currency, rates, rate_last_updated]
  );
};
