import { getBaseUrl } from "../../utils/helpers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Create your service using a base URL and expected endpoints
export const AuthApi = createApi({
  reducerPath: "AuthApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${getBaseUrl()}`,
    prepareHeaders: (headers) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      // const token = (getState() as RootState).user.token;
      // if (token) {
      //   headers.set("Authorization", `Bearer ${token}`);
      //   // headers.set('Authentication', `Bearer ${token}`);
      // }
      // headers.set('x-auth-apiKey', '1');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    authenticate: builder.mutation({
      query: (body: FormData) => ({
        url: "/getUserDetails.php",
        method: "POST",
        body,
      }),
    }),
    getTransactionHistory: builder.mutation({
      query: (body: FormData) => ({
        url: "/getTransactionHistory.php",
        method: "POST",
        body,
      }),
    }),
    getMemoryType: builder.mutation({
      query: () => ({
        url: "/getMemoryTypes.php",
        method: "GET",
      }),
    }),
    getPinType: builder.mutation({
      query: () => ({
        url: "/getPinTypes.php",
        method: "GET",
      }),
    }),
    login: builder.mutation({
      query: (body: FormData) => ({
        url: "/login.php",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useAuthenticateMutation,
  useGetTransactionHistoryMutation,
  useGetMemoryTypeMutation,
  useGetPinTypeMutation,
} = AuthApi;
