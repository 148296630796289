import { getBaseUrl } from "../../utils/helpers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Create your service using a base URL and expected endpoints
export const TransferApi = createApi({
  reducerPath: "TransferApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${getBaseUrl()}`,
    prepareHeaders: (headers) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      // const token = (getState() as RootState).user.token;
      // if (token) {
      //   headers.set("Authorization", `Bearer ${token}`);
      //   // headers.set('Authentication', `Bearer ${token}`);
      // }
      // headers.set('x-auth-apiKey', '1');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    transferToSelf: builder.mutation({
      query: (body: FormData) => ({
        url: "/transferToSelf.php",
        method: "POST",
        body,
      }),
    }),
    createInternationalTransfer: builder.mutation({
      query: (body: FormData) => ({
        url: "/createInternationalTransfer.php",
        method: "POST",
        body,
      }),
    }),
    makePayment: builder.mutation({
      query: (body: FormData) => ({
        url: "/makePayment.php",
        method: "POST",
        body,
      }),
    }),
    confirmOTP: builder.mutation({
      query: (body: FormData) => ({
        url: "/confirmTransactionOTP.php",
        method: "POST",
        body,
      }),
    }),
    createBeneficiary: builder.mutation({
      query: (body: FormData) => ({
        url: "/createBeneficiary.php",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useConfirmOTPMutation,
  useCreateInternationalTransferMutation,
  useMakePaymentMutation,
  useTransferToSelfMutation,
  useCreateBeneficiaryMutation,
} = TransferApi;
