import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { combineReducers } from "redux";
import { persistReducer, PersistConfig } from "redux-persist";
import userReducer, { defaultUserState } from "./reducers/user.reducer";
import accessReducer from "./reducers/access.reducer";
import storage from "redux-persist/lib/storage";
import expireInTransform from "redux-persist-transform-expire-in";
import { defaultAccessState } from "./reducers/access.reducer";
import { AuthApi } from "./services/auth.service";
import { TransferApi } from "./services/transfer.service";
import { ExchangeApi } from "./services/exchange.service";
// import logger from 'redux-logger';

const accessExpireIn = 1 * 15 * 60 * 1000; // expire in 15m
const accessExpirationKey = "iom_online_banking_release_access_expiration_key";

const userExpireIn = 1 * 3 * 60 * 1000; // expire in 3m
const userExpirationKey = "iom_online_banking_release_user_expiration_key";

const persistConfig = {
  key: "iom_online_banking_release",
  version: 1,
  storage,
  whitelist: ["user", "settings"],
};

const accessConfig: PersistConfig<any> = {
  key: "iom_online_banking_release_access",
  version: 1,
  storage,
  transforms: [
    expireInTransform(accessExpireIn, accessExpirationKey, [
      defaultAccessState,
    ]),
  ],
  // whitelist: ["user", "settings"],
};

const userConfig: PersistConfig<any> = {
  key: "iom_online_banking_release_user",
  version: 1,
  storage,
  transforms: [
    expireInTransform(userExpireIn, userExpirationKey, [defaultUserState]),
  ],
  // whitelist: ["user", "settings"],
};

const access = persistReducer(accessConfig, accessReducer);
const user = persistReducer(userConfig, userReducer);

const reducers = combineReducers({
  user,
  access,
  [AuthApi.reducerPath]: AuthApi.reducer,
  [TransferApi.reducerPath]: TransferApi.reducer,
  [ExchangeApi.reducerPath]: ExchangeApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      AuthApi.middleware,
      TransferApi.middleware,
      ExchangeApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
setupListeners(store.dispatch);
